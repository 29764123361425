import Header from "../../components/Header"
import "./style.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "../../components/Styles/global.css"
import {
  Button,
  Card,
  Container,
  Form,
  Spinner,
  Toast,
  ToastContainer,
} from "react-bootstrap"
import { useEffect, useState } from "react"

import { ModalData } from "../../components/Modal/ModalQuestions"
import { IQuestions } from "../../interfaces/IQuestions"
import { getAllQuestions, createQuestions } from "../../services/questions"
import { IModalQuestions } from "../../interfaces/IModalQuestions"
import {
  ArrowRepeat,
  CheckCircleFill,
  PencilFill,
  Trash2Fill,
  TrashFill,
} from "react-bootstrap-icons"

import { DataGrid, GridColDef, ptBR } from "@mui/x-data-grid"
import { Stack } from "@mui/material"

function ListQuestions() {
  // Variável para chamar o modal com informações.
  const [modalInfoShow, setModalInfoShow] = useState<IModalQuestions | null>(
    null
  )
  const [questions, setQuestions] = useState<IQuestions[] | null>(null)
  const [description, setDescription] = useState<string>()
  const [validated, setValidated] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const columns: GridColDef[] = [
    //Id
    {
      field: "id",
      headerName: "ID",
      align: "center",
      headerAlign: "center",
      minWidth: 100,
    },

    //Description
    {
      field: "description",
      headerName: "Descrição",
      flex: 1,
      minWidth: 150,
      editable: true,
    },

    //Tools
    {
      field: "tools",
      headerName: "Ações",
      width: 300,
      sortable: false,
      filterable: false,
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            <Button
              style={{
                width: 120,
              }}
              variant="success"
              onClick={() =>
                setModalInfoShow({
                  question: {
                    id: params.row.id,
                    description: params.row.description,
                  },
                  show: true,
                  type: "update",
                })
              }
            >
              <PencilFill width={20} height={20} /> Editar
            </Button>{" "}
            <Button
              style={{
                width: 120,
              }}
              variant="danger"
              onClick={() =>
                setModalInfoShow({
                  question: {
                    id: params.row.id,
                    description: params.row.description,
                  },
                  show: true,
                  type: "delete",
                })
              }
            >
              <Trash2Fill width={20} height={20} /> Excluir
            </Button>{" "}
          </Stack>
        )
      },
    },
  ]

  const rows: { id: number; description: string }[] = questions
    ? questions.map((question) => ({
        id: question.id,
        description: question.description,
      }))
    : []

  const [toastInfo, setToastInfo] = useState<{
    show: boolean
    title: string
    text: string
    variant: string
  }>({ show: false, title: "", text: "", variant: "" })

  const handleSubmitCreate = (event: any) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()

      let data = {
        description: description,
      }
      createQuestions(data)
        .then((response) => {
          setToastInfo({
            show: true,
            title: "Criação",
            text: "Questão criado com sucesso!",
            variant: "bg-success",
          })
          loadList()
          setDescription("")
          setValidated(false)
          console.log(response)
        })
        .catch((error) => {
          loadList()
          console.log(error)
        })
    }
    setValidated(true)
  }

  const handleModalClose = (
    show: boolean,
    title: string,
    text: string,
    variant: string
  ) => {
    setToastInfo({ show: show, title: title, text: text, variant: variant })
    loadList()
  }

  const loadList = () => {
    setLoading(true)
    getAllQuestions().then((response) => {
      setQuestions(response.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    loadList()
  }, [])

  return (
    <body className="background">
      <Header />
      <Container fluid>
        <Card>
          <Card.Header className="bg-dark text-white">
            Inserir nova questão de avaliação
          </Card.Header>
          <Card.Body>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmitCreate}
            >
              <Form.Group controlId="validationCustom04">
                <Form.Label>
                  {" "}
                  Escreva uma nova questão para as avaliações
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Não esqueça de inserir '?' no final, caso seja uma pergunta"
                  onChange={(value) => setDescription(value.target.value)}
                  value={description}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Por favor, insira o enunciado da questão no campo acima!
                </Form.Control.Feedback>
              </Form.Group>
              <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-3">
                <Button variant="success" type="submit">
                  Cadastrar questão
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>

        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spinner
              animation="border"
              role="status"
              style={{ width: "100px", height: "100px" }}
            >
              <span className="visually-hidden">
                Carregando profissionais...
              </span>
            </Spinner>
          </div>
        ) : (
          <Card className="mt-3">
            <Card.Header className="bg-dark text-white">
              Listagem das questões para avaliação já cadastradas
            </Card.Header>
            <Card.Body>
              <div style={{ height: "100%", width: "100%" }}>
                <DataGrid
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  rows={rows}
                  columns={columns}
                />
              </div>
            </Card.Body>
          </Card>
        )}
      </Container>

      <ModalData
        onClose={handleModalClose}
        props={modalInfoShow}
        onHide={() => {
          setModalInfoShow({
            show: false,
            question: null,
            type: null,
          })
        }}
      />

      <ToastContainer
        className="p-2"
        position="top-start"
        style={{ zIndex: 1 }}
      >
        <Toast
          onClose={() =>
            setToastInfo({ show: false, title: "", text: "", variant: "" })
          }
          show={toastInfo?.show}
          delay={3000}
          autohide
        >
          <Toast.Header className={toastInfo.variant + " text-white"}>
            {toastInfo.title === "Alteração" ? (
              <ArrowRepeat width={20} height={20} style={{ marginRight: 5 }} />
            ) : toastInfo.title === "Exclusão" ? (
              <TrashFill width={20} height={20} style={{ marginRight: 5 }} />
            ) : (
              <CheckCircleFill
                width={20}
                height={20}
                style={{ marginRight: 5 }}
              />
            )}
            <strong className="me-auto">{toastInfo?.title}</strong>
            <small>Evento</small>
          </Toast.Header>
          <Toast.Body className="bg-dark text-white">
            {toastInfo?.text}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </body>
  )
}

export default ListQuestions

import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap"
import { ModalType } from "../../enums/modalType.enum"
import { IModalProps } from "../../interfaces/IModalProps"

interface IModalTableProps {
  props: IModalProps | null
  onHide: () => void
}

export function ModalImage(props: any) {
  // tipar essas props
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Imagem de perfil
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <Image src={props.url} fluid />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  )
}

// Função que pega as inputs através do seu "name' e os add dentro do "ModalData"
export function ModalData({ props, onHide }: IModalTableProps) {
  return (
    <>
      <Modal
        onHide={onHide}
        show={props?.status}
        backdrop="static"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-dark text-white">
          <Modal.Title id="contained-modal-title-vcenter">
            {props?.type === ModalType.USER
              ? "Detalhes do usuário"
              : "Detalhes da Empresa"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container fluid="md">
            {/* Dados do Cliente/Prestador */}
            <Card>
              <Card.Body>
                <Card.Title>
                  {props?.type === ModalType.USER
                    ? "Dados do Usuário"
                    : "Dados da Empresa"}
                </Card.Title>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        {props?.type === ModalType.USER
                          ? "Nome"
                          : "Nome da empresa"}
                      </Form.Label>
                      <Form.Control
                        name="name"
                        defaultValue={
                          props?.type === ModalType.USER
                            ? props.user?.name
                            : props?.professional?.company_name ||
                              props?.professional?.user.name
                        }
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  {props?.type === ModalType.PROFESSIONAL && (
                    <>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Categorias</Form.Label>
                          <Form.Control
                            name="profession"
                            placeholder={props.professional?.categories
                              .map((category) => {
                                return category.description
                              })
                              .join(", ")}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </>
                  )}
                </Row>
              </Card.Body>
            </Card>

            {/* Dados de contato */}
            <Card className="mt-2">
              <Card.Body>
                <Card.Title>Contato</Card.Title>
                <Row>
                  <Col xs={12} md={props?.type === ModalType.USER ? 6 : 12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        name="email"
                        defaultValue={
                          props?.type === ModalType.USER
                            ? props.user?.email
                            : props?.professional?.user.email
                        }
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Label>WhatsApp</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        name="phone"
                        defaultValue={
                          props?.type === ModalType.USER
                            ? props.user?.phone
                            : props?.professional?.user.phone
                        }
                        disabled
                      />
                    </InputGroup>
                  </Col>
                  {props?.type === ModalType.PROFESSIONAL && (
                    <>
                      <Col xs={12} md={6}>
                        <Form.Label>Telefone</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            name="telephone"
                            placeholder={props.professional?.telephone}
                            disabled={!props.edit}
                          />
                        </InputGroup>
                      </Col>
                    </>
                  )}
                </Row>
              </Card.Body>
            </Card>

            {/* Dados de endereço */}
            <Card className="mt-2">
              <Card.Body>
                <Card.Title>Endereço</Card.Title>
                <Row>
                  <Col xs={12} md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Endereço</Form.Label>
                      <Form.Control
                        name="address"
                        defaultValue={
                          props?.type === ModalType.USER
                            ? `${props?.user?.address.address}, ${props?.user?.address.number} - ${props?.user?.address.neighborhood} ${(props?.user?.address.complement != "") ? " - "+ props?.user?.address.complement : "" }`
                            : `${props?.professional?.address.address}, ${props?.professional?.address.number} - ${props?.professional?.address.neighborhood} ${(props?.professional?.address.complement != "") ? " - "+ props?.professional?.address.complement : "" }`
                        }
                        disabled
                      />
                    </Form.Group>
                  </Col>                  
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        name="city"
                        defaultValue={
                          props?.type === ModalType.USER
                            ? props?.user?.address.city
                            : props?.professional?.address.city
                        }
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Estado</Form.Label>
                      <Form.Control
                        name="state"
                        defaultValue={
                          props?.type === ModalType.USER
                            ? props.user?.address.state
                            : props?.professional?.address.state
                        }
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={onHide}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

import Header from "../../components/Header"
import "./styles.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "../../components/Styles/global.css"
import {
  Badge,
  Button,
  Card,
  Container,
  Image,
  Spinner,
  Toast,
  ToastContainer,
  ToggleButton,
} from "react-bootstrap"
import { useEffect, useState } from "react"
import { ModalImage, ModalData } from "../../components/Modal/ModalTable"
import { getAllUsers, updateUser, anonymizeUser } from "../../services/user"
import { IUser } from "../../interfaces/IUser"
import { IModalProps } from "../../interfaces/IModalProps"

import { DataGrid, GridColDef, ptBR } from "@mui/x-data-grid"
import { Stack } from "@mui/material"
import { Status } from "../../enums/status.enum"
import { formatTime } from "../../lib/formatTime"
import {
  Check2,
  CheckCircleFill,
  EyeFill,
  XCircleFill,
  XLg,
} from "react-bootstrap-icons"
import { ModalType } from "../../enums/modalType.enum"

const styles = {
  img_perfil: {
    width: "45px",
    height: "45px",
  },
}

function ListUsers() {
  //Variável para chamar o modal de visualização da logo
  const [modalImageShow, setModalImageShow] = useState<{
    status: boolean
    // Variável que passa qual o caminho ('url / 'src') da imagem.
    url: string
  } | null>(null)

  // Variável para chamar o modal com informações.
  const [modalInfoShow, setModalInfoShow] = useState<IModalProps | null>(null)

  const [users, setUsers] = useState<IUser[] | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isLoadingRemove, setLoadingRemove] = useState<boolean>(false)
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      align: "center",
      headerAlign: "center",
      maxWidth: 90,
    },
    // Name
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      minWidth: 550,
      valueGetter: (params) => params.row.user.name,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            <div className="d-flex align-items-center">
              {/* <Image
                style={styles.img_perfil}
                src={params.row.user.picture || "https://picsum.photos/450"} //mudar para foto placeholder (embaixo tambem)
                roundedCircle
                onClick={() =>
                  setModalImageShow({
                    status: true,
                    url: params.row.user.picture || "https://picsum.photos/450",
                  })
                }
              /> */}
              {/* <div className="ms-3"> */}
                <p className="fw-bold mb-1">{params.row.user.name}</p>
                {/* <p className="text-muted mb-0">{params.row.user.email}</p>
              </div> */}
            </div>
          </Stack>
        )
      },
    },

    //Status
    {
      field: "status",
      headerName: "Status",
      maxWidth: 90,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            <div className="d-flex justify-content-center effect">
              <Badge
                className="effect"
                bg={params.row.status === Status.ACTIVE ? "success" : "danger"}
              >
                {params.row.status}
              </Badge>
            </div>
          </Stack>
        )
      },
    },

    //Period
    {
      field: "period",
      headerName: "Período",
      maxWidth: 125,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            {formatTime(new Date(params.row.period), new Date())}
          </Stack>
        )
      },
    },

    //tools
    {
      field: "tools",
      headerName: "Ações",
      flex: 1,
      minWidth: 300,
      maxWidth: 350,
      sortable: false,
      filterable: false,
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            <Button
              style={{
                width: 120,
              }}
              variant="primary"
              onClick={() =>
                setModalInfoShow({
                  status: true,
                  professional: null,
                  user: params.row.user,
                  edit: false,
                  type: ModalType.USER,
                })
              }
            >
              <EyeFill width={20} height={20} /> Visualizar
            </Button>{" "}

            { isLoadingRemove && 
              <Spinner
              animation="border"
              role="status"
              style={{ width: "20px", height: "20px" }}
              >
                <span className="visually-hidden">
                  ...
                </span>
              </Spinner>
            }

            { !isLoadingRemove &&
              <ToggleButton
                style={{
                  width: 120,
                }}
                className="effect"
                id={"toggle-check-" + params.row.id}
                type="checkbox"
                variant={
                  params.row.status === Status.INACTIVE ? "success" : "danger"
                }
                checked={params.row.status === Status.ACTIVE}
                value="1"
                onChange={async () => {
                  if (window.confirm(`Deseja realmente remover o usuário ${params.row.user.name} ?`)){
                    setLoadingRemove(true)
                    await anonymizeUser(
                      {
                        status:
                          params.row.status === Status.INACTIVE
                            ? Status.ACTIVE
                            : Status.INACTIVE,
                      },
                      params.row.id
                    )
                    setLoadingRemove(false)
                  
                    loadList()

                    setToastInfo({
                      show: true,
                      title:
                        params.row.status === Status.INACTIVE
                          ? "Ativação"
                          : "Desativação",
                      text:
                        params.row.status === Status.INACTIVE
                          ? "Ativado com sucesso!"
                          : "Desativado com sucesso!",
                      variant:
                        params.row.status === Status.INACTIVE
                          ? "bg-success"
                          : "bg-danger",
                    })
                  }
                }}
              >
                {params.row.status === Status.INACTIVE ? (
                  <Check2 width={20} height={20} />
                ) : (
                  <XLg width={20} height={20} />
                )}{" "}
                {params.row.status === Status.INACTIVE ? "Ativar" : "Desativar"}
              </ToggleButton>
            }
          </Stack>
        )
      },
    },
  ]

  const loadList = () => {
    getAllUsers().then((response) => {
      setUsers(response.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    setLoading(true)
    loadList()
  }, [])

  const [toastInfo, setToastInfo] = useState<{
    show: boolean
    title: string
    text: string
    variant: string
  }>({ show: false, title: "", text: "", variant: "" })

  return (
    <div className="background">
      <Header />
      <Container fluid>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spinner
              animation="border"
              role="status"
              style={{ width: "100px", height: "100px" }}
            >
              <span className="visually-hidden">
                Carregando profissionais...
              </span>
            </Spinner>
          </div>
        ) : (
          <Card>
            <Card.Header className="bg-dark text-white">
              Usuários
            </Card.Header>
            <Card.Body>
              <div style={{ height: "100%", width: "100%" }}>
                <DataGrid
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  rows={
                    users
                      ? users.map((user) => ({
                          id: user.id,
                          user: user,
                          status: user.status,
                          period: user.created_at,
                        }))
                      : []
                  }
                  columns={columns}
                />
              </div>
            </Card.Body>
          </Card>
        )}
        <ModalImage
          show={modalImageShow?.status}
          url={modalImageShow?.url}
          onHide={() => {
            setModalImageShow({ status: false, url: "" })
          }}
        />

        <ModalData
          props={modalInfoShow}
          onHide={() => {
            setModalInfoShow({
              status: false,
              user: null,
              professional: null,
              edit: false,
              type: null,
            })
          }}
        />
      </Container>

      <ToastContainer
        className="p-2"
        position="top-start"
        style={{ zIndex: 1 }}
      >
        <Toast
          onClose={() =>
            setToastInfo({ show: false, title: "", text: "", variant: "" })
          }
          show={toastInfo?.show}
          delay={3000}
          autohide
        >
          <Toast.Header className={toastInfo.variant + " text-white"}>
            {toastInfo.title === "Ativação" ? (
              <CheckCircleFill
                width={20}
                height={20}
                style={{ marginRight: 5 }}
              />
            ) : (
              <XCircleFill width={20} height={20} style={{ marginRight: 5 }} />
            )}
            <strong className="me-auto">{toastInfo?.title}</strong>
            <small>Evento</small>
          </Toast.Header>
          <Toast.Body className="bg-dark text-white">
            {toastInfo?.text}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  )
}

export default ListUsers

import api from "../api"

export async function login(data: {
  phone: string
  password: string
}): Promise<any> {
  return api({
    url: "/user/admin-login",
    method: "POST",
    timeout: 5000,
    data: data,
    headers: { Accept: "application/json" },
  })
    .then((result) => {
      api.defaults.headers.common.Authorization = `Bearer ${result.data.access_token}`
      return Promise.resolve(result)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export async function logout(): Promise<any> {
  return api({
    url: "/user/logout",
    method: "PUT",
    timeout: 5000,
    headers: { Accept: "application/json" },
  })
    .then((result) => {
      return Promise.resolve(result)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

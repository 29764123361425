export function formatTime(date1: Date, date2: Date): string {
  const milliseconds = date2.getTime() - date1.getTime()

  const units = [
    { unit: "ano", divisor: 1000 * 60 * 60 * 24 * 30 * 12 },
    { unit: "mês", divisor: 1000 * 60 * 60 * 24 * 30 },
    { unit: "dia", divisor: 1000 * 60 * 60 * 24 },
  ]

  for (const { unit, divisor } of units) {
    const value = Math.floor(milliseconds / divisor)
    if (value !== 0) {
      return `${value} ${unit}${
        value !== 1 ? (unit === "mês" ? "es" : "s ") : ""
      }`
    }
  }

  return "recém-chegado"
}

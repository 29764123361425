import { Button, Card, Col, Container, Form, Modal, Row } from "react-bootstrap"
import { IModalQuestions } from "../../interfaces/IModalQuestions"
import React, { useState } from "react"
import { deleteQuestions, updateQuestions } from "../../services/questions"

interface IModalTableProps {
  props: IModalQuestions | null
  onHide: () => void
  onClose: (show: boolean, title: string, text: string, variant: string) => void
}

// Função que pega as inputs através do seu "name' e os add dentro do "ModalData"
export function ModalData({ props, onHide, onClose }: IModalTableProps) {
  const [description, setDescription] = useState<string>()
  const [validated, setValidated] = useState(false)

  const handleSubmitUpdate = (event: any) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()

      let data = {
        description: description,
      }

      updateQuestions(data, props?.question?.id)
        .then((response) => {
          console.log(response)
          onHide()
          onClose(
            true,
            "Alteração",
            "Questão alterada com sucesso!",
            "bg-success"
          )
        })
        .catch((error) => {
          console.log(error)
          onHide()
          onClose(
            true,
            "Alteração",
            "Houve um erro ao alterar a questão! Tente novamente",
            "bg-warning"
          )
        })
    }
    setValidated(true)
  }

  const handleSubmitDelete = (value: React.FormEvent) => {
    value.preventDefault()

    deleteQuestions(props?.question?.id)
      .then((response) => {
        console.log(response)
        onHide()
        onClose(true, "Exclusão", "Questão excluída com sucesso", "bg-danger")
      })
      .catch((error) => {
        console.log(error)
        onHide()
        onClose(
          true,
          "Exclusão",
          "Houve um erro ao excluir a questão! Tente novamente",
          "bg-warning"
        )
      })
  }

  return (
    <>
      <Modal
        onHide={onHide}
        show={props?.show}
        backdrop="static"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form noValidate validated={validated} onSubmit={handleSubmitUpdate}>
          <Modal.Header className="bg-dark text-white">
            <Modal.Title id="contained-modal-title-vcenter">
              {props?.type === "update"
                ? "Alterar enunciado da questão"
                : "Excluir questão"}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Container fluid="md">
              {/* Dados do Cliente/Prestador */}
              <Card>
                <Card.Body>
                  <Row>
                    <Col xs={12} md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {props?.type === "update"
                            ? "Enunciado Atual"
                            : "Enunciado da questão"}
                        </Form.Label>
                        <Form.Control
                          defaultValue={props?.question?.description}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    {props?.type === "update" && (
                      <>
                        <hr></hr>
                        <Col xs={12} md={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="validationCustom04"
                          >
                            <Form.Label> Novo Enunciado</Form.Label>
                            <Form.Control
                              name="description"
                              defaultValue={props?.question?.description}
                              onChange={(value) =>
                                setDescription(value.target.value)
                              }
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Por favor, insira o novo enunciado da questão no
                              campo acima!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </>
                    )}
                  </Row>
                </Card.Body>
              </Card>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            {props?.type === "update" ? (
              <Button variant="primary" type="submit">
                Alterar enunciado
              </Button>
            ) : (
              <Button variant="danger" onClick={handleSubmitDelete}>
                Excluir enunciado
              </Button>
            )}
            <Button variant="outline-secondary" onClick={onHide}>
              Voltar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

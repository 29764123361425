import Header from "../../components/Header"
import "./styles.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "../../components/Styles/global.css"
import {
  Badge,
  Button,
  Card,
  Container,
  Image,
  Spinner,
  Toast,
  ToastContainer,
  ToggleButton,
} from "react-bootstrap"
import { useEffect, useState } from "react"
import { ModalImage, ModalData } from "../../components/Modal/ModalTable"
import { IProfessional } from "../../interfaces/IProfessional"
import { IModalProps } from "../../interfaces/IModalProps"
import {
  anonymizeProfessional,
  getAllProfessionals,
  getProfessionalLogo,
  updateProfessional,
} from "../../services/professional"
import { Status } from "../../enums/status.enum"
import { ModalType } from "../../enums/modalType.enum"
import { DataGrid, GridColDef, ptBR } from "@mui/x-data-grid"
import { Stack } from "@mui/material"
import {
  Check2,
  CheckCircleFill,
  EyeFill,
  Star,
  StarFill,
  XCircleFill,
  XLg,
} from "react-bootstrap-icons"
import { formatTime } from "../../lib/formatTime"

const styles = {
  img_perfil: {
    width: "45px",
    height: "45px",
  },
}

function ListProfessional() {
  //Variável para chamar o modal de visualização da logo
  const [modalImageShow, setModalImageShow] = useState<{
    status: boolean
    // Variável que passa qual o caminho ('url / 'src') da imagem.
    url: string
  } | null>(null)

  // Variável para chamar o modal com informações.
  const [modalInfoShow, setModalInfoShow] = useState<IModalProps | null>(null)
  const [professionals, setProfessionals] = useState<IProfessional[] | null>(
    null
  )
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isLoadingRemove, setLoadingRemove] = useState<boolean>(false)

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      align: "center",
      headerAlign: "center",
      maxWidth: 50,
    },

    // Name
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      maxWidth: 400,
      valueGetter: (params) =>
        params.row.professional.company_name ||
        params.row.professional.user.name,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            <div className="d-flex align-items-center">
              <Image
                style={styles.img_perfil}
                src={
                  params.row.professional.logo || "https://picsum.photos/450"
                } //mudar para foto placeholder (embaixo tambem)
                roundedCircle
                onClick={() =>
                  setModalImageShow({
                    status: true,
                    url:
                      params.row.professional.logo ||
                      "https://picsum.photos/450",
                  })
                }
              />
              <div className="ms-3">
                <p className="fw-bold mb-1">
                  {params.row.professional.company_name ||
                    params.row.professional.user?.name}
                </p>
                <p className="text-muted mb-0">
                  {params.row.professional.user?.email}
                </p>
              </div>
            </div>
          </Stack>
        )
      },
    },

    // Category
    {
      field: "category",
      headerName: "Categoria",
      minWidth: 500,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            <p className="fw-normal mb-1">
              {params.row.professional.categories?.map((category: any) => {
                  return category.description
                })
                .join(", ")}
            </p>
          </Stack>
        )
      },
    },

    //Status
    {
      field: "status",
      headerName: "Status",
      maxWidth: 90,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            <div className="d-flex justify-content-center">
              <Badge
                className="effect"
                bg={params.row.status === Status.ACTIVE ? "success" : "danger"}
              >
                {params.row.status}
              </Badge>
            </div>
          </Stack>
        )
      },
    },

    //Evaluations
    {
      field: "evaluations",
      headerName: "Avaliação",
      maxWidth: 100,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            { isNaN(params.row.evaluations) 
              ? <Badge bg="danger">Sem avaliações</Badge> 
              : <Badge
              bg={
                params.row.evaluations > 3.5
                  ? "success"
                  : params.row.evaluations > 1.5
                  ? "warning"
                  : "danger"
              }
            >
              <div
                style={{
                  display: "inline-flex",
                  position: "relative",
                  textAlign: "left",
                }}
              >
                {[...new Array(5)].map((_arr, index) => {
                  const showEmptyIcon = params.row.evaluations < index + 1
                  const isRatingWithPrecision = params.row.evaluations % 1 !== 0
                  const isRatingEqualToIndex =
                    Math.ceil(params.row.evaluations) === index + 1
                  const showRatingWithPrecision =
                    isRatingWithPrecision && isRatingEqualToIndex
                  return (
                    <div style={{ position: "relative" }} key={index}>
                      <div
                        style={{
                          width: showRatingWithPrecision
                            ? `${(params.row.evaluations % 1) * 100}%`
                            : "0%",
                          overflow: "hidden",
                          position: "absolute",
                        }}
                      >
                        <StarFill />
                      </div>
                      <div>{showEmptyIcon ? <Star /> : <StarFill />}</div>
                    </div>
                  )
                })}
              </div>
              <p className="my-2">{params.row.evaluations} / 5</p>
            </Badge>
            }
          </Stack>
        )
      },
    },

    //Period
    {
      field: "period",
      headerName: "Período",
      maxWidth: 125,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            {formatTime(new Date(params.row.period), new Date())}
          </Stack>
        )
      },
    },

    //tools
    {
      field: "tools",
      headerName: "Ações",
      flex: 1,
      minWidth: 300,
      maxWidth: 350,
      sortable: false,
      filterable: false,
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            <Button
              style={{
                width: 120,
              }}
              variant="primary"
              onClick={() =>
                setModalInfoShow({
                  status: true,
                  user: null,
                  professional: params.row.professional,
                  edit: false,
                  type: ModalType.PROFESSIONAL,
                })
              }
            >
              <EyeFill width={20} height={20} /> Visualizar
            </Button>{" "}
            { isLoadingRemove && 
              <Spinner
              animation="border"
              role="status"
              style={{ width: "20px", height: "20px" }}
              >
                <span className="visually-hidden">
                  ...
                </span>
              </Spinner>
            }

            { !isLoadingRemove && 
              <ToggleButton
                className="effect"
                style={{
                  width: 120,
                }}
                id={"toggle-check-" + params.row.id}
                type="checkbox"
                variant={
                  params.row.status === Status.INACTIVE ? "success" : "danger"
                }
                checked={params.row.status === Status.ACTIVE}
                value="1"
                onChange={async () => {
                  if (window.confirm(`Deseja realmente remover o profissional ${params.row.professional.company_name || params.row.professional.user.name} ?`)){
                    setLoadingRemove(true)
                    await anonymizeProfessional(
                      {
                        status:
                          params.row.status === Status.INACTIVE
                            ? Status.ACTIVE
                            : Status.INACTIVE,
                      },
                      params.row.id
                    )
                    setLoadingRemove(false)

                    loadList()

                    setToastInfo({
                      show: true,
                      title:
                        params.row.status === Status.INACTIVE
                          ? "Ativação"
                          : "Desativação",
                      text:
                        params.row.status === Status.INACTIVE
                          ? "Ativado com sucesso!"
                          : "Desativado com sucesso!",
                      variant:
                        params.row.status === Status.INACTIVE
                          ? "bg-success"
                          : "bg-danger",
                    })
                  }
                }}
              >
                {params.row.status === Status.INACTIVE ? (
                  <Check2 width={20} height={20} />
                ) : (
                  <XLg width={20} height={20} />
                )}{" "}
                {params.row.status === Status.INACTIVE ? "Ativar" : "Desativar"}
              </ToggleButton>
            }
          </Stack>
        )
      },
    },
  ]

  const loadList = () => {
    setLoading(true)
    getAllProfessionals().then(async (response) => {  
      setProfessionals(response.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    setLoading(true)
    loadList()
  }, [])

  const [toastInfo, setToastInfo] = useState<{
    show: boolean
    title: string
    text: string
    variant: string
  }>({ show: false, title: "", text: "", variant: "" })

  return (
    <div className="background">
      <Header />
      <Container fluid>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spinner
              animation="border"
              role="status"
              style={{ width: "50px", height: "50px" }}
            >
              <span className="visually-hidden">
                Carregando profissionais...
              </span>
            </Spinner>
          </div>
        ) : (
          <Card>
            <Card.Header className="bg-dark text-white">
              Profissionais
            </Card.Header>
            <Card.Body>
              <div style={{ height: "100%", width: "100%" }}>
                <DataGrid
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  rows={
                    professionals
                      ? professionals.map((professional) => ({
                          id: professional.id,
                          professional: professional,
                          status: professional.status,
                          evaluations: (
                            professional.evaluations?.reduce(
                              (acc: any, val: any) =>
                                acc + parseFloat(val.avg_answers),
                              0
                            ) / professional.evaluations?.length
                          ).toFixed(1),
                          period: professional.created_at,
                        }))
                      : []
                  }
                  columns={columns}
                />
              </div>

              <ModalImage
                show={modalImageShow?.status}
                url={modalImageShow?.url}
                onHide={() => {
                  setModalImageShow({ status: false, url: "" })
                }}
              />

              <ModalData
                props={modalInfoShow}
                onHide={() => {
                  setModalInfoShow({
                    status: false,
                    user: null,
                    professional: null,
                    edit: false,
                    type: null,
                  })
                }}
              />
            </Card.Body>
          </Card>
        )}
      </Container>

      <ToastContainer
        className="p-2"
        position="top-start"
        style={{ zIndex: 1 }}
      >
        <Toast
          onClose={() =>
            setToastInfo({ show: false, title: "", text: "", variant: "" })
          }
          show={toastInfo?.show}
          delay={3000}
          autohide
        >
          <Toast.Header className={toastInfo.variant + " text-white"}>
            {toastInfo.title === "Ativação" ? (
              <CheckCircleFill
                width={20}
                height={20}
                style={{ marginRight: 5 }}
              />
            ) : (
              <XCircleFill width={20} height={20} style={{ marginRight: 5 }} />
            )}
            <strong className="me-auto">{toastInfo?.title}</strong>
            <small>Evento</small>
          </Toast.Header>
          <Toast.Body className="bg-dark text-white">
            {toastInfo?.text}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  )
}

export default ListProfessional

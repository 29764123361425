import api from "../api"

export async function getAllQuestions(): Promise<any> {
  return api
    .get(`/questions/all`)
    .then((result) => {
      return Promise.resolve(result)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export async function createQuestions(data: any): Promise<any>{
  return api({
      url: "/questions/create",
      method: "POST",
      timeout: 5000,
      data: data,
      headers: {Accept: 'application/json'}
  }).then((result) => {
      return Promise.resolve(result)
  }).catch((error) => {
      return Promise.reject(error)
  })
}

export async function updateQuestions(data: any, id: any): Promise<any> {
  return api({
    url: `/questions/update/${id}`,
    method: "PUT",
    timeout: 5000,
    data: data,
    headers: { Accept: "application/json" },
  })
    .then((result) => {
      return Promise.resolve(result)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export async function deleteQuestions(id: any): Promise<any> {
  return api
    .delete(`/questions/delete/${id}`)
    .then((result) => {
      return Promise.resolve(result)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

import { useNavigate } from "react-router-dom";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import {
  BuildingFillGear,
  DoorOpen,
  HouseFill,
  PersonFillGear,
  PersonLinesFill,
  TagsFill,
} from "react-bootstrap-icons";
import { logout } from "../../services/auth";
import ImageLogo from "../../images/logo1.png";
function Header() {
  const navigate = useNavigate();

  return (
    <header>
      <Navbar
        expand={false}
        className="bg-body-tertiary mb-3"
        bg="dark"
        data-bs-theme="dark"
      >
        <Container fluid>
          <Navbar.Brand href="#">
            <img
              alt=""
              src={ImageLogo}
              width="auto"
              height="70"
              className="d-inline-block"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-false`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-false`}
            aria-labelledby={`offcanvasNavbarLabel-expand-false`}
            placement="start"
            bg="dark"
            data-bs-theme="dark"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-false`}>
                Menu
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link
                  className="nav_link"
                  onClick={() => navigate("/home")}
                >
                  <HouseFill className="icon_link" width={25} height={25} />{" "}
                  Sugestões
                </Nav.Link>
                <Nav.Link
                  className="nav_link"
                  onClick={() => navigate("/listUser")}
                >
                  <PersonFillGear
                    className="icon_link"
                    width={25}
                    height={25}
                  />{" "}
                  Usuários
                </Nav.Link>
                <Nav.Link
                  className="nav_link"
                  onClick={() => navigate("/listProfessional")}
                >
                  <BuildingFillGear
                    className="icon_link"
                    width={25}
                    height={25}
                  />{" "}
                  Profissionais
                </Nav.Link>
                <Nav.Link
                  className="nav_link"
                  onClick={() => navigate("/listQuestions")}
                >
                  <PersonLinesFill
                    className="icon_link"
                    width={25}
                    height={25}
                  />{" "}
                  Perguntas para Avaliação
                </Nav.Link>
                <Nav.Link
                  className="nav_link"
                  onClick={() => navigate("/listCategories")}
                >
                  <TagsFill className="icon_link" width={25} height={25} />{" "}
                  Categorias
                </Nav.Link>
              </Nav>
              <div className="d-grid gap-2 mt-5">
                <Button
                  style={{ height: 40 }}
                  onClick={() => {
                    logout();
                    window.localStorage.removeItem("hash");
                    window.localStorage.removeItem("name");
                    navigate("/");
                  }}
                  variant="danger"
                  size="sm"
                >
                  <DoorOpen width={20} height={20} /> Sair
                </Button>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;

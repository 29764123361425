import { Button, Container, Form, Image } from "react-bootstrap"
import "./styles.css"
import "../../components/Styles/global.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import ImageLogin from "../../images/Login/imgLogin2.png"
import { login } from "../../services/auth"

function Login() {
  const navigate = useNavigate()
  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")
  const [validated, setValidated] = useState(false)

  const handleSubmit = (event: any) => {
    const form = event.currentTarget

    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
      login({ phone: user, password })
        .then((response) => {
          window.localStorage.setItem("hash", response.data.access_token)
          window.localStorage.setItem("name", response.data.name)
          navigate("/home")
        })
        .catch(() => {
          alert("Usuário não encontrado")
        })
    }
    setValidated(true)
  }

  return (
    <Container className="background">
      <section className="text-lg-start">
        <div className="py-4">
          <div className="row g-0 align-items-center">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <div className="card shadow p-3 mb-5 bg-body rounded">
                <div className="card-body">
                  <h2 className="fw-bold mb-5 text-center">Me salva - Login</h2>

                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    {/* <!-- Campo Usuário --> */}
                    <div className="form-outline mb-4">
                      <Form.Group className="mb-3" controlId="formGroupEmail">
                        <Form.Label>Usuário</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Digite seu usuário"
                          onChange={(e) => setUser(e.target.value)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Insira um usuário para logar!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    {/* <!-- Campo Senha --> */}
                    <div className="form-outline mb-4">
                      <Form.Group
                        className="mb-3"
                        controlId="formGroupPassword"
                      >
                        <Form.Label>Senha</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Digite sua senha"
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Insira sua senha para logar!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    {/* <!-- Checkbox --> */}
                    <div className="form-check d-flex justify-content-center mb-4">
                      <Form.Group className="mb-3" id="formGridCheckbox">
                        <Form.Check
                          type="checkbox"
                          label="Manter-me conectado!"
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </div>

                    <div className="d-grid gap-2">
                      <Button variant="primary" type="submit" size="lg">
                        Entrar
                      </Button>
                    </div>

                    {/* <!-- Botão de registrar --> */}
                  </Form>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mb-5 mb-lg-0">
              <Image
                className="w-100 rounded-4 shadow-4"
                src={ImageLogin}
                fluid
              />
              ;
            </div>
          </div>
        </div>
      </section>
    </Container>
  )
}

export default Login

import { Button, Card, Col, Container, Form, Modal, Row } from "react-bootstrap"
import { IModalCategoriesProps } from "../../interfaces/IModalCategoriesProps"
import React, { useState } from "react"
import {
  deleteCategory,
  updateCategory,
  uploadCategoryIcon,
} from "../../services/categories"
import { ImagePicker } from "../ImagePicker/ImagePicker"

interface IModalTableProps {
  props: IModalCategoriesProps | null
  onHide: () => void
  onClose: (show: boolean, title: string, text: string, variant: string) => void
}

export function ModalData({ props, onHide, onClose }: IModalTableProps) {
  const [description, setDescription] = useState<string>(
    props?.category?.description ?? ""
  )
  const [validated, setValidated] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File>()
  const [isLoading, setLoading] = useState(false)

  const handleSubmitUpdate = async (event: any) => {
    setLoading(true)
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      setLoading(false)
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
      if (props) {
        if (selectedFile)
          await uploadCategoryIcon(props.category!.id, selectedFile)
        await updateCategory(props.category!.id, { description })
          .then((response) => {
            setLoading(false)
            onHide()
            onClose(
              true,
              "Alteração",
              "Categoria alterada com sucesso!",
              "bg-success"
            )
          })
          .catch((error) => {
            setLoading(false)
            console.log(error)
            onHide()
            onClose(
              true,
              "Alteração",
              "Houve um erro ao alterar a categoria! Tente novamente",
              "bg-warning"
            )
          })
      }else{
        setLoading(false)
      }      
    }
    setValidated(true)
  }

  const handleSubmitDelete = (value: React.FormEvent) => {
    value.preventDefault()

    deleteCategory(props?.category?.id)
      .then((response) => {
        console.log(response)
        onHide()
        onClose(true, "Exclusão", "Categoria excluída com sucesso", "bg-danger")
      })
      .catch((error) => {
        console.log(error)
        onHide()
        onClose(
          true,
          "Exclusão",
          "Houve um erro ao excluir a categoria! Tente novamente",
          "bg-warning"
        )
      })
  }

  return (
    <>
      <Modal
        onHide={onHide}
        show={props?.show}
        backdrop="static"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form noValidate validated={validated} onSubmit={handleSubmitUpdate}>
          <Modal.Header className="bg-dark text-white">
            <Modal.Title id="contained-modal-title-vcenter">
              {props?.type === "update"
                ? "Alterar nome da categoria"
                : "Excluir categoria"}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Container fluid="md">
              <Card>
                <Card.Body>
                  <Row>
                    <Col sm={"auto"}>
                      <Form.Label>Ícone da categoria</Form.Label>
                      <ImagePicker
                        handleFileChange={(files: FileList) =>
                          setSelectedFile(files[0])
                        }
                        initialImage={props?.category?.icon!}
                        disabled={props?.type === "delete"}
                      />
                    </Col>
                    <div className="vr" style={{ padding: 0 }}></div>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {props?.type === "update"
                            ? "Nome Atual"
                            : "Nome da categoria"}
                        </Form.Label>
                        <Form.Control
                          defaultValue={props?.category?.description}
                          disabled
                        />
                      </Form.Group>
                      {props?.type === "update" && (
                        <>
                          <hr></hr>
                          <Form.Group
                            className="mb-3"
                            controlId="validationCustom04"
                          >
                            <Form.Label> Novo Nome</Form.Label>
                            <Form.Control
                              name="description"
                              defaultValue={props?.category?.description}
                              onChange={(value) =>
                                setDescription(value.target.value)
                              }
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Por favor, insira o novo nome da categoria no
                              campo acima!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            { isLoading && <span>Salvando...</span>}
            
            {!isLoading && 
              <div>
                {props?.type === "update" ? (
                  <Button variant="primary" type="submit">
                    Alterar categoria
                  </Button>
                ) : (
                  <Button variant="danger" onClick={handleSubmitDelete}>
                    Excluir categoria
                  </Button>
                )}
              </div>
            }
            <Button variant="outline-secondary" onClick={onHide}>
              Voltar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

import { useRef, useState, useEffect } from "react"
import { Image } from "react-bootstrap"
import { PencilSquare } from "react-bootstrap-icons"

export const ImagePicker: React.FC<{
  handleFileChange: (files: FileList) => void
  initialImage?: string
  disabled?: boolean
}> = (props: {
  handleFileChange: (files: FileList) => void
  initialImage?: string
  disabled?: boolean
}) => {
  const inputFile = useRef<HTMLInputElement | null>(null)
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState<string | undefined>(props.initialImage)

  useEffect(() => {
    if (!selectedFile) {
      setPreview(props.initialImage ?? undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    return () => URL.revokeObjectURL(objectUrl)
  }, [props.initialImage, selectedFile])

  const onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    props.handleFileChange(e.target.files)
    setSelectedFile(e.target.files[0])
  }

  function handleClick() {
    if (inputFile.current) {
      inputFile.current.click()
      setPreview(undefined)
    }
  }

  return (
    <>
      {!props.disabled && (
        <input
          style={{ display: "none" }}
          type="file"
          accept="image/*"
          ref={inputFile}
          onChange={onSelectFile}
        />
      )}
      <div
        style={{
          maxHeight: "200px",
          maxWidth: "200px",
          minHeight: "100px",
          minWidth: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: props.disabled ? "default" : "pointer",
        }}
        onClick={handleClick}
      >
        <Image
          src={preview ?? "https://fakeimg.pl/200x200?text=%20"}
          fluid
          rounded
        />
        {!props.disabled && (
          <PencilSquare
            style={{
              fontSize: "30px",
              zIndex: 10,
              position: "absolute",
              mixBlendMode: "difference",
            }}
            color="white"
          />
        )}
      </div>
    </>
  )
}

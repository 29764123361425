import React from "react"
import { Routes, Route } from "react-router-dom"
import Login from "./pages/Login"
import Home from "./pages/Home"
import ListProfessional from "./pages/ListProfessional"
import ListUsers from "./pages/ListUsers"
import ListQuestions from "./pages/ListQuestions"
import ListCategories from "./pages/ListCategories"

function MainRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/home" element={<Home />} />
      <Route path="/listProfessional" element={<ListProfessional />} />
      <Route path="/listUser" element={<ListUsers />} />
      <Route path="/listQuestions" element={<ListQuestions />} />
      <Route path="/listCategories" element={<ListCategories />} />
    </Routes>
  )
}

export default MainRoutes

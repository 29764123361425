import api from "../api"

export async function getAllProfessionals(): Promise<any> {
  return api
    .get(`/professional/all`)
    .then((result) => {
      return Promise.resolve(result)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export async function getProfessionalLogo(id: number): Promise<any> {
  return api
    .get(`/professional/${id}/logo/get`)
    .then((result) => {
      return Promise.resolve(result)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export async function updateProfessional(data: any, id: any): Promise<any> {
  return api({
    url: `/professional/update/${id}`,
    method: "PUT",
    timeout: 5000,
    data: data,
    headers: { Accept: "application/json" },
  })
    .then((result) => {
      return Promise.resolve(result)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export async function anonymizeProfessional(data: any, id: any): Promise<any> {
  return api({
    url: `/professional/anonymize/${id}`,
    method: "PUT",
    timeout: 5000,
    data: data,
    headers: { Accept: "application/json" },
  })
    .then((result) => {
      return Promise.resolve(result)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

import { BrowserRouter } from "react-router-dom";
import MainRoutes from "./routes";
import "./components/Styles/global.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <BrowserRouter>
      <MainRoutes />
    </BrowserRouter>
  );
}

export default App;
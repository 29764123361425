import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { IModalQuestions } from "../../interfaces/IModalQuestions";
import React, { useState } from "react";
import { deleteQuestions, updateQuestions } from "../../services/questions";
import { IModalSuggestion } from "../../interfaces/IModalSuggestions";

interface IModalTableProps {
  props: IModalSuggestion | null;
  onHide: () => void;
  onClose: (
    show: boolean,
    title: string,
    text: string,
    variant: string
  ) => void;
}

// Função que pega as inputs através do seu "name' e os add dentro do "ModalData"
export function ModalData({ props, onHide, onClose }: IModalTableProps) {
  const [description, setDescription] = useState<string>();
  const [validated, setValidated] = useState(false);

  const handleSubmitUpdate = (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();

      let data = {
        description: description,
      };

      updateQuestions(data, props?.suggestion?.id)
        .then((response) => {
          console.log(response);
          onHide();
          onClose(
            true,
            "Alteração",
            "Questão alterada com sucesso!",
            "bg-success"
          );
        })
        .catch((error) => {
          console.log(error);
          onHide();
          onClose(
            true,
            "Alteração",
            "Houve um erro ao alterar a questão! Tente novamente",
            "bg-warning"
          );
        });
    }
    setValidated(true);
  };

  const handleSubmitDelete = (value: React.FormEvent) => {
    value.preventDefault();

    deleteQuestions(props?.suggestion?.id)
      .then((response) => {
        console.log(response);
        onHide();
        onClose(true, "Exclusão", "Questão excluída com sucesso", "bg-danger");
      })
      .catch((error) => {
        console.log(error);
        onHide();
        onClose(
          true,
          "Exclusão",
          "Houve um erro ao excluir a questão! Tente novamente",
          "bg-warning"
        );
      });
  };

  return (
    <>
      <Modal
        onHide={onHide}
        show={props?.show}
        backdrop="static"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form noValidate validated={validated} onSubmit={handleSubmitUpdate}>
          <Modal.Header className="bg-dark text-white">
            <Modal.Title id="contained-modal-title-vcenter">
              Visualizar informações da sugestão
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Container fluid="md">
              {/* Descrição da sugestão */}
              <Card>
                <Card.Body>
                  <Row>
                    <Col xs={12} md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Descrição da sugestão</Form.Label>
                        <Form.Control
                          defaultValue={props?.suggestion?.description}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* Dados do Cliente/Prestador */}
              <Card>
                <Card.Body>
                  <Card.Title>DAdos do usuário</Card.Title>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                          name="name"
                          defaultValue={props?.suggestion?.user?.name}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* Dados de contato */}
              <Card className="mt-2">
                <Card.Body>
                  <Card.Title>Contato</Card.Title>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          name="email"
                          defaultValue={props?.suggestion?.user.email}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label>WhatsApp</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          name="phone"
                          defaultValue={props?.suggestion?.user.phone}
                          disabled
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* Dados de endereço */}
              <Card className="mt-2">
                <Card.Body>
                  <Card.Title>Endereço</Card.Title>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Cidade</Form.Label>
                        <Form.Control
                          name="city"
                          defaultValue={props?.suggestion?.user.address.city}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Estado</Form.Label>
                        <Form.Control
                          name="state"
                          defaultValue={props?.suggestion?.user.address.state}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={onHide}>
              Fechar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

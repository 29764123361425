import Header from "../../components/Header"
import "./styles.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "../../components/Styles/global.css"
import {
  Button,
  Card,
  Container,
  Form,
  Toast,
  ToastContainer,
  Image,
  Col,
  Row,
  Spinner,
  Badge,
  ToggleButton,
} from "react-bootstrap"
import { useEffect, useState } from "react"

import { ModalData } from "../../components/Modal/ModalCategories"
import { IModalCategoriesProps } from "../../interfaces/IModalCategoriesProps"
import {
  ArrowRepeat,
  Check2,
  CheckCircleFill,
  PencilFill,
  Trash2Fill,
  TrashFill,
  XLg,
} from "react-bootstrap-icons"

import { DataGrid, GridColDef, ptBR } from "@mui/x-data-grid"
import { Stack } from "@mui/material"
import { ICategory } from "../../interfaces/ICategory"
import {
  createCategory,
  getAllCategories,
  getCategoryIcon,
  updateCategory,
  uploadCategoryIcon,
} from "../../services/categories"
import { ImagePicker } from "../../components/ImagePicker/ImagePicker"
import { Status } from "../../enums/status.enum"

function ListCategories() {
  // Variável para chamar o modal com informações.
  const [modalInfoShow, setModalInfoShow] =
    useState<IModalCategoriesProps | null>(null)
  const [categories, setCategories] = useState<ICategory[] | null>(null)
  const [description, setDescription] = useState<string>()
  const [validated, setValidated] = useState<boolean>(false)
  const [selectedFile, setSelectedFile] = useState<File | null>()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isLoadingSave, setLoadingSave] = useState<boolean>(false)
  const columns: GridColDef[] = [
    //Id
    {
      field: "id",
      headerName: "ID",
      align: "center",
      headerAlign: "center",
      minWidth: 100,
    },

    {
      field: "icon",
      headerName: "Ícone",
      align: "center",
      headerAlign: "center",
      minWidth: 50,
      renderCell: (params) => {
        return (
          <Image
            style={{
              width: "45px",
              height: "45px",
            }}
            src={params.row.icon}
            roundedCircle
          />
        )
      },
    },

    //Description
    {
      field: "description",
      headerName: "Descrição",
      flex: 1,
      minWidth: 150,
      editable: true,
    },

    //Status
    {
      field: "status",
      headerName: "Status",
      maxWidth: 90,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            <div className="d-flex justify-content-center effect">
              <Badge
                className="effect"
                bg={params.row.status === Status.ACTIVE ? "success" : "danger"}
              >
                {params.row.status}
              </Badge>
            </div>
          </Stack>
        )
      },
    },

    //Tools
    {
      field: "tools",
      headerName: "Ações",
      width: 300,
      sortable: false,
      filterable: false,
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            <Button
              style={{
                width: 120,
              }}
              variant="success"
              onClick={() =>
                setModalInfoShow({
                  category: {
                    id: params.row.id,
                    description: params.row.description,
                    icon: params.row.icon,
                    status: params.row.status,
                  },
                  show: true,
                  type: "update",
                })
              }
            >
              <PencilFill width={20} height={20} /> Editar
            </Button>{" "}
            <ToggleButton
              className="effect"
              style={{
                width: 120,
              }}
              id={"toggle-check-" + params.row.id}
              type="checkbox"
              variant={
                params.row.status === Status.INACTIVE ? "success" : "danger"
              }
              checked={params.row.status === Status.ACTIVE}
              value="1"
              onChange={async () => {
                setLoading(true)
                await updateCategory(params.row.id, {
                  status:
                    params.row.status === Status.INACTIVE
                      ? Status.ACTIVE
                      : Status.INACTIVE,
                })

                loadList()

                setToastInfo({
                  show: true,
                  title:
                    params.row.status === Status.INACTIVE
                      ? "Ativação"
                      : "Desativação",
                  text:
                    params.row.status === Status.INACTIVE
                      ? "Ativado com sucesso!"
                      : "Desativado com sucesso!",
                  variant:
                    params.row.status === Status.INACTIVE
                      ? "bg-success"
                      : "bg-danger",
                })
              }}
            >
              {params.row.status === Status.INACTIVE ? (
                <Check2 width={20} height={20} />
              ) : (
                <XLg width={20} height={20} />
              )}{" "}
              {params.row.status === Status.INACTIVE ? "Ativar" : "Desativar"}
            </ToggleButton>
          </Stack>
        )
      },
    },
  ]

  const [toastInfo, setToastInfo] = useState<{
    show: boolean
    title: string
    text: string
    variant: string
  }>({ show: false, title: "", text: "", variant: "" })

  const handleSubmitCreate = async (event: any) => {
    setLoadingSave(true)
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      setLoadingSave(false)
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()

      let data = {
        description: description,
      }

      let createdCategoryId: any
      await createCategory(data)
        .then((response) => {
          setLoadingSave(false)
          setToastInfo({
            show: true,
            title: "Criação",
            text: "Categoria criada com sucesso!",
            variant: "bg-success",
          })
          setDescription("")
          setValidated(false)
          createdCategoryId = response.data.id
        })
        .catch((error) => {
          setLoadingSave(false)
          loadList()
          console.log(error)
        })
      await uploadCategoryIcon(createdCategoryId, selectedFile!)
      loadList()
    }
    setValidated(true)
  }

  const handleModalClose = (
    show: boolean,
    title: string,
    text: string,
    variant: string
  ) => {
    setToastInfo({ show: show, title: title, text: text, variant: variant })
    loadList()
  }

  const loadList = () => {
    getAllCategories().then(async (response) => {      
      setCategories(response.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    setLoading(true)
    loadList()
  }, [])

  return (
    <div className="background">
      <Header />
      <Container fluid>
        <Card>
          <Card.Header className="bg-dark text-white">
            Inserir nova categoria
          </Card.Header>
          <Card.Body>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmitCreate}
            >
              <Row>
                <Col sm={"auto"}>
                  <Form.Label>Selecione um ícone para a categoria</Form.Label>
                  <ImagePicker
                    handleFileChange={(files: FileList) =>
                      setSelectedFile(files.item(0))
                    }
                  />
                </Col>
                <div className="vr" style={{ padding: 0 }}></div>
                <Col style={{ position: "relative" }}>
                  <Form.Group controlId="validationCustom04">
                    <Form.Label> Escreva uma nova categoria</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ex: 'Eletricista'"
                      onChange={(value) => setDescription(value.target.value)}
                      value={description}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Por favor, insira o nome da categoria no campo acima!
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div
                    className="d-grid gap-2 justify-content-md-end mt-3"
                    style={{
                      position: "absolute",
                      right: 0,
                      bottom: 0,
                      paddingRight: "inherit",
                    }}
                  >
                    { isLoadingSave && <span>Salvando...</span>}
                    { !isLoadingSave &&
                      <Button
                        variant="success"
                        type="submit"
                        style={{ flexGrow: 1, flexShrink: 1, flexBasis: "auto" }}
                      >
                        Cadastrar categoria
                      </Button>
                    }
                  </div>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>

        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spinner
              animation="border"
              role="status"
              style={{ width: "100px", height: "100px" }}
            >
              <span className="visually-hidden">
                Carregando profissionais...
              </span>
            </Spinner>
          </div>
        ) : (
          <Card className="mt-3">
            <Card.Header className="bg-dark text-white">
              Listagem das categorias já cadastradas
            </Card.Header>
            <Card.Body>
              <div style={{ height: "100%", width: "100%" }}>
                <DataGrid
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  rows={
                    categories
                      ? categories.map((category) => ({
                          id: category.id,
                          icon: category.icon,
                          description: category.description,
                          status: category.status,
                        }))
                      : []
                  }
                  columns={columns}
                />
              </div>
            </Card.Body>
          </Card>
        )}
      </Container>

      <ModalData
        onClose={handleModalClose}
        props={modalInfoShow}
        onHide={() => {
          setModalInfoShow({
            show: false,
            category: null,
            type: null,
          })
        }}
      />

      <ToastContainer
        className="p-2"
        position="top-start"
        style={{ zIndex: 1 }}
      >
        <Toast
          onClose={() =>
            setToastInfo({ show: false, title: "", text: "", variant: "" })
          }
          show={toastInfo?.show}
          delay={3000}
          autohide
        >
          <Toast.Header className={toastInfo.variant + " text-white"}>
            {toastInfo.title === "Alteração" ? (
              <ArrowRepeat width={20} height={20} style={{ marginRight: 5 }} />
            ) : toastInfo.title === "Exclusão" ? (
              <TrashFill width={20} height={20} style={{ marginRight: 5 }} />
            ) : (
              <CheckCircleFill
                width={20}
                height={20}
                style={{ marginRight: 5 }}
              />
            )}
            <strong className="me-auto">{toastInfo?.title}</strong>
            <small>Evento</small>
          </Toast.Header>
          <Toast.Body className="bg-dark text-white">
            {toastInfo?.text}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  )
}

export default ListCategories

import api from "../api";

export async function getAllSuggestions(): Promise<any> {
  return api
    .get(`/suggestions/all`)
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

import api from "../api"
import { Status } from "../enums/status.enum"

export async function getAllCategories(): Promise<any> {
  return api
    .get(`/category/all`)
    .then((result) => {
      return Promise.resolve(result)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export async function getCategoriesByProfessional(professionalId: number) {
  return api
    .get(`/category/find-many/${professionalId}`)
    .then((result) => {
      return Promise.resolve(result)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export async function createCategory(data: any): Promise<any> {
  console.log(data)
  return api({
    url: "/category/create",
    method: "POST",
    timeout: 5000,
    data: data,
    headers: { Accept: "application/json" },
  })
    .then((result) => {
      return Promise.resolve(result)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export async function uploadCategoryIcon(id: number, file: File) {
  const formData = new FormData()
  formData.append("file", file)
  return api
    .put(`/category/${id}/icon/upload`, formData)
    .then((result) => {
      return Promise.resolve(result)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export async function getCategoryIcon(id: number): Promise<any> {
  return api
    .get(`/category/${id}/icon/get`)
    .then((result) => {
      return Promise.resolve(result)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export async function updateCategory(
  id: number,
  data: { description?: string; status?: Status }
): Promise<any> {
  return api({
    url: `/category/update/${id}`,
    method: "PUT",
    timeout: 5000,
    data,
    headers: { Accept: "application/json" },
  })
    .then((result) => {
      return Promise.resolve(result)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export async function deleteCategory(id: any): Promise<any> {
  return api
    .delete(`/category/delete/${id}`)
    .then((result) => {
      return Promise.resolve(result)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

import Header from "../../components/Header"
import "./styles.css"
import "../../components/Styles/global.css"
import "bootstrap/dist/css/bootstrap.min.css"
import {
  Button,
  Card,
  Container,
  Spinner,
  Toast,
  ToastContainer,
} from "react-bootstrap"
import { useEffect, useState } from "react"

import { ModalData } from "../../components/Modal/ModalSuggestions"
import {
  ArrowRepeat,
  CheckCircleFill,
  EyeFill,
  TrashFill,
} from "react-bootstrap-icons"

import { DataGrid, GridColDef, ptBR } from "@mui/x-data-grid"
import { Stack } from "@mui/material"
import { getAllSuggestions } from "../../services/suggestions"
import { ISuggestions } from "../../interfaces/ISuggestion"
import { IModalSuggestion } from "../../interfaces/IModalSuggestions"
import { IUser } from "../../interfaces/IUser"
import { formatTime } from "../../lib/formatTime"

function Home() {
  // Variável para chamar o modal com informações.
  const [modalInfoShow, setModalInfoShow] = useState<IModalSuggestion | null>(
    null
  )
  const [suggestions, setSuggestions] = useState<ISuggestions[] | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)
  const columns: GridColDef[] = [
    //Id
    {
      field: "id",
      headerName: "ID",
      align: "center",
      headerAlign: "center",
      minWidth: 100,
    },

    //Description
    {
      field: "description",
      headerName: "Descrição",
      flex: 1,
      minWidth: 150,
      editable: true,
    },

    //data/hora
    {
      field: "created_at",
      headerName: "Data/hora",
      maxWidth: 125,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            {formatTime(new Date(params.row.created_at), new Date())}
          </Stack>
        )
      },
    },

    //Tools
    {
      field: "tools",
      headerName: "Ações",
      width: 300,
      sortable: false,
      filterable: false,
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            <Button
              style={{
                width: 120,
              }}
              variant="primary"
              onClick={() =>
                setModalInfoShow({
                  suggestion: {
                    id: params.row.id,
                    description: params.row.description,
                    user: params.row.user,
                    created_at: params.row.created_at,
                  },
                  show: true,
                  type: "view",
                })
              }
            >
              <EyeFill width={20} height={20} /> Visualizar
            </Button>{" "}
          </Stack>
        )
      },
    },
  ]

  const rows: { id: number; description: string; user: IUser }[] = suggestions
    ? suggestions.map((suggestion) => ({
        id: suggestion.id,
        description: suggestion.description,
        user: suggestion.user,
        created_at: suggestion.created_at
      }))
    : []

  const [toastInfo, setToastInfo] = useState<{
    show: boolean
    title: string
    text: string
    variant: string
  }>({ show: false, title: "", text: "", variant: "" })

  const handleModalClose = (
    show: boolean,
    title: string,
    text: string,
    variant: string
  ) => {
    setToastInfo({ show: show, title: title, text: text, variant: variant })
    loadList()
  }

  const loadList = () => {
    setLoading(true)
    getAllSuggestions().then((response) => {
      setSuggestions(response.data)
    })
    setLoading(false)
  }

  useEffect(() => {
    loadList()
  }, [])

  return (
    <body className="background">
      <Header />
      <Container fluid>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spinner
              animation="border"
              role="status"
              style={{ width: "100px", height: "100px" }}
            >
              <span className="visually-hidden">
                Carregando sugestões...
              </span>
            </Spinner>
          </div>
        ) : (
          <Card style={{ height: "100%", width: "100%" }} className="mt-3">
            <Card.Header className="bg-dark text-white">
              Últimas sugestões enviadas
            </Card.Header>
            <Card.Body>
              <div style={{ height: "100%", width: "100%" }}>
                <DataGrid
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  rows={rows}
                  columns={columns}
                />
              </div>
            </Card.Body>
          </Card>
        )}
      </Container>

      <ModalData
        onClose={handleModalClose}
        props={modalInfoShow}
        onHide={() => {
          setModalInfoShow({
            show: false,
            suggestion: null,
            type: null,
          })
        }}
      />

      <ToastContainer
        className="p-2"
        position="top-start"
        style={{ zIndex: 1 }}
      >
        <Toast
          onClose={() =>
            setToastInfo({ show: false, title: "", text: "", variant: "" })
          }
          show={toastInfo?.show}
          delay={3000}
          autohide
        >
          <Toast.Header className={toastInfo.variant + " text-white"}>
            {toastInfo.title === "Alteração" ? (
              <ArrowRepeat width={20} height={20} style={{ marginRight: 5 }} />
            ) : toastInfo.title === "Exclusão" ? (
              <TrashFill width={20} height={20} style={{ marginRight: 5 }} />
            ) : (
              <CheckCircleFill
                width={20}
                height={20}
                style={{ marginRight: 5 }}
              />
            )}
            <strong className="me-auto">{toastInfo?.title}</strong>
            <small>Evento</small>
          </Toast.Header>
          <Toast.Body className="bg-dark text-white">
            {toastInfo?.text}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </body>
  )
}

export default Home
